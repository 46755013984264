import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
    overflow: hidden;
    margin: 0 auto;
    width: ${(props) => `${props.width}px`};

 `;

const Com = ({ children, ...props }) => (
    <Content
        {...props}
        width={props.width || 1200}
    >
        {children}
    </Content>
);
export default Com;
