import React from 'react';
import { Layout } from 'antd';

import { Routes } from 'src/router';
import TopHeader from './components/TopHeader';
import BottomFooter from './components/BottomFooter';

import './index.less';

const { Header, Content, Footer } = Layout;

const TopBottomLayout = (props) => (
    <div className="layout-wrap">
        <TopHeader />
        <Content
            className="main-content"
            style={{
                minHeight: document.body.clientHeight - 56,
            }}
        >
            <div className="sub-main-content">
                <Routes routes={props.routes} />
            </div>
        </Content>
        <BottomFooter />
    </div>
);

export default TopBottomLayout;
