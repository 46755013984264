import React from 'react';

export default (props) => {
    const {
        theme,
        img,
        height,
        children,
        style = {},
        ...extraprops
    } = props;
    return (
        <div
            style={{
                width: '100%',
                height,
                minHeight: 320,
                background: `${theme} url(${img}) no-repeat center`,
                ...style,
            }}
            {...extraprops}
        >
            {children}
        </div>
    );
    // return (
    //     <div
    //         style={{
    //             width: '100%',
    //             textAlign: 'center',
    //             background: theme,
    //             position: 'relative',
    //             ...style,
    //             minHeight: 200,
    //         }}
    //         {...extraprops}
    //     >
    //         <img
    //             src={img}
    //             style={{ maxHeight: 560, margin: '0 auto' }}
    //             alt="banner"
    //         />
    //         {children}
    //     </div>
    // );
};
