import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { Routes } from 'src/router';
import { isMobile } from 'src/tools/utils';
import allRouters from 'src/data/allRouters';
import { BackTop, ScrollToTop } from './components';

import './assets/css/index.less';
import './font.less';

const App = () => {
    useEffect(() => {
        // 设置高亮的菜单
        const activeMenu = window.location.pathname.split('/').pop();
        sessionStorage.setItem('menuActiveKey', activeMenu || 'home');
    }, []);

    return (
        <Router>
            <ScrollToTop className="App">
                <Routes routes={allRouters} />
                <BackTop />
            </ScrollToTop>
        </Router>
    );
};

export default App;
