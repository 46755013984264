import React from 'react';
import styled from 'styled-components';

const LoadingDiv = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Loading = () => (
    <LoadingDiv>
        {/* <img src={`${process.env.PUBLIC_URL}/loading.gif`} alt="loading" /> */}
        加载中....
    </LoadingDiv>
);

export default Loading;
