import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { Loading } from 'src/components';

const SmartRouter = (route) => (
    route.tag === 'Redirect'
        ? (
            <Redirect
                from={route.from}
                to={route.to}
                exact={route.exact}
                render={(props) => (<route.component {...props} routes={route.routes} />)}
            />
        )
        : (
            <Route
                path={route.path}
                exact={route.exact}
                render={(props) => (
                    <route.component {...props} routes={route.routes} />
                )}
            />
        )
);

const Routes = (props) => (
    <Suspense fallback={<Loading />}>
        <Switch>
            {
                props.routes.map(
                    (route, i) => <SmartRouter key={JSON.stringify(route)} {...route} />,
                )
            }
        </Switch>
    </Suspense>
);

export {
    Routes,
    SmartRouter,
};
