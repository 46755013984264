import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
    font-size:40px;
    text-align: center;
    color:#333333;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 1px;
    .sub{
        color:#666;
    }
    .description{
        padding-top:20px;
        font-size:18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
        /* text-indent:30px; */
        color: #666;
    }
`;

const Title = (props) => {
    const {
        title, sub, children, style = {}, className = '',
    } = props;

    return (
        <Content style={{ ...style }} className={className}>
            <span>{title}</span>
            <span className="sub">{sub}</span>
            {children ? <div className="description">{children}</div> : null}
        </Content>
    );
};

export default Title;
