import { lazy } from 'react';

// 布局组件
import layouts from '../layout';
// 配置文件
import { prefixRoute } from '../configs';

export default [
    {
        tag: 'Redirect',
        from: '/',
        to: `${prefixRoute}/home`,
        exact: true,
    },
    {
        tag: 'Route',
        component: layouts,
        routes: [
            // 首页如果需要在layout布局内就放在这里，如果不需要放在layout布局内
            {
                name: '首页',
                tag: 'Route',
                path: `${prefixRoute}/home`,
                component: lazy(() => import('../pages/home/index')),
                exact: true,
            },
            {
                name: 'iframe',
                tag: 'Route',
                path: `${prefixRoute}/iframe`,
                component: lazy(() => import('../pages/iframe/index')),
            },

            // 送餐机器人H1
            {
                name: '送餐机器人H1',
                tag: 'Route',
                path: `${prefixRoute}/robot-H1`,
                component: lazy(() => import('../pages/robot-H1/index')),
                exact: true,
            },
            // 送餐机器人R2
            {
                name: '智能送物机器人R2',
                tag: 'Route',
                path: `${prefixRoute}/robot-R2`,
                component: lazy(() => import('../pages/robot-R2')),
                exact: true,
            },
            // 送餐机器人R3
            {
                name: '智能送物机器人R3',
                tag: 'Route',
                path: `${prefixRoute}/robot-S3`,
                component: lazy(() => import('../pages/robot-S3/index')),
                exact: true,
            },
            // 迪小萌M8
            {
                name: '迪小萌M8',
                tag: 'Route',
                path: `${prefixRoute}/robot-M8`,
                component: lazy(() => import('../pages/robot-M8')),
                exact: true,
            },
            // 迪小萌X8
            {
                name: '迪小萌X8',
                tag: 'Route',
                path: `${prefixRoute}/robot-X8`,
                component: lazy(() => import('../pages/robot-X8')),
                exact: true,
            },
            // 查询不到路由显示NotFound
            {
                tag: 'Route',
                component: lazy(() => import('../components/not-found/index')),
            },

        ],
    },
    // 查询不到路由显示NotFound
    {
        tag: 'Route',
        component: lazy(() => import('../components/not-found/index')),
    },
];
