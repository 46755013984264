import React, { useState, useEffect } from 'react';
import { Menu, Affix } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import { prefixRoute } from 'src/configs';

import './TopHeader.less';

const menuActiveKey = sessionStorage.getItem('menuActiveKey') || 'home';

const TopHeader = () => {
    const history = useHistory();
    const [current, setCurrent] = useState(menuActiveKey);

    useEffect(() => {
        const menuActiveKey = sessionStorage.getItem('menuActiveKey');
        // menuActiveKey && setCurrent(menuActiveKey);
        // 为了防止移动端切换过来的导航菜单高亮不一致，换成这个方式
        const activeMenu = window.location.pathname.split('/').pop();
        menuActiveKey && setCurrent(activeMenu);
    }, [sessionStorage.getItem('menuActiveKey')]);

    const menuClick = (e) => {
        setCurrent(e.key);
        sessionStorage.setItem('menuActiveKey', e.key);
    };

    // 跳转页面
    const toPage = (path) => {
        history.push(`${prefixRoute}/${path}`);
    };

    const renderSubItem = (key, name, desc) => (
        <div style={{ userSelect: 'none' }} onClick={() => toPage(key)}>
            <div className="subMenu-item-img">
                <img
                    src={
                        require(`../../assets/images/submenu/submenu-${key}.png`)
                            .default
                    }
                    alt={name}
                />
            </div>
            <div className="font-24 subMenu-item-title bold">
                {name}
                <p className="font-16 fontWeight-400">{desc}</p>
            </div>
        </div>
    );

    const MENUS = [
        {
            label: <p onClick={() => toPage('home')}>首页</p>,
            key: 'home',
        },
        {
            label: '产品中心',
            key: 'product',
            icon: <DownOutlined />,
            children: [
                {
                    label: renderSubItem(
                        'robot-H1',
                        '迪小送H1',
                        '智能送餐机器人',
                    ),
                    key: 'robot-H1',
                },
                {
                    label: renderSubItem(
                        'robot-R2',
                        '迪小送R2',
                        '智能送餐机器人',
                    ),
                    key: 'robot-R2',
                },
                {
                    label: renderSubItem(
                        'robot-S3',
                        '迪小送S3',
                        '智能送物机器人',
                    ),
                    key: 'robot-S3',
                },
                {
                    label: renderSubItem(
                        'robot-X8',
                        '迪小萌X8',
                        '多功能服务机器人',
                    ),
                    key: 'robot-X8',
                },
                {
                    label: renderSubItem(
                        'robot-M8',
                        '迪小萌M8',
                        '桌面服务机器人',
                    ),
                    key: 'robot-M8',
                },
            ],
        },
    ];
    
    return (
        <Affix
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                zIndex: 99999,
            }}
        >
            <div className="header-wrap">
                <header className="layout-header">
                    <div className="left">
                        <h1 className="logo">申迪</h1>
                        <Menu
                            onClick={menuClick}
                            defaultSelectedKeys={[current]}
                            selectedKeys={[current]}
                            mode="horizontal"
                            style={{
                                minWidth: 600,
                                justifyContent: 'flex-end',
                            }}
                            items={MENUS}
                        />
                    </div>
                </header>
            </div>
        </Affix>
    );
};

export default TopHeader;
