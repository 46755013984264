import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { prefixRoute } from 'src/configs';

const date = new Date();

const BottomFooter = () => {
    const history = useHistory();
    const gotoUrl = (url, router) => {
        if (url) {
            const dom = document.querySelector(`#${url}`);
            if (dom) {
                window.scrollTo({
                    top: dom.offsetTop - 100,
                    behavior: 'smooth',
                });
            } else {
                history.push(`${router}#${url}`);
            }
        } else {
            history.push(router);
        }
    };
    useEffect(() => {
        setTimeout(() => {
            if (history.location.hash) {
                const dom = document.querySelector(history.location.hash);
                if (dom) {
                    console.log(dom.offsetTop - 100);
                    window.scrollTo({
                        top: dom.offsetTop - 100,
                        behavior: 'smooth',
                    });
                }
            }
        }, 0);
    }, [history.location.hash]);
    return (
        <footer className="layout-footer">
            <ul className="info-wrap">
                <li className="infos">
                    <ul className="product ul-item">
                        <li className="title">产品中心</li>
                        <li
                            className="item"
                            onClick={() => {
                                gotoUrl(false, `${prefixRoute}/robot-H1`);
                            }}
                        >
                            迪小送H1
                        </li>
                        <li
                            className="item"
                            onClick={() => {
                                gotoUrl(false, `${prefixRoute}/robot-R2`);
                            }}
                        >
                            迪小送R2
                        </li>
                        <li
                            className="item"
                            onClick={() => {
                                gotoUrl(false, `${prefixRoute}/robot-S3`);
                            }}
                        >
                            迪小送S3
                        </li>

                        {/* <li
                            className="item"
                            onClick={() => {
                                gotoUrl(`${prefixRoute}/robot-T5`);
                            }}
                        >
                            迪巡视T5
                        </li> */}
                    </ul>
                    <ul className="product ul-item">
                        <li className="title" style={{ color: '#474747' }}>
                            产品中心
                        </li>
                        <li
                            className="item"
                            onClick={() => {
                                gotoUrl(false, `${prefixRoute}/robot-X8`);
                            }}
                        >
                            迪小萌X8
                        </li>
                        <li
                            className="item"
                            onClick={() => {
                                gotoUrl(false, `${prefixRoute}/robot-M8`);
                            }}
                        >
                            迪小萌M8
                        </li>

                        {/* <li
                            className="item"
                            onClick={() => {
                                gotoUrl(`${prefixRoute}/easy-flow`);
                            }}
                        >
                            小迪C4
                        </li>
                        <li
                            className="item"
                            onClick={() => {
                                gotoUrl(`${prefixRoute}/easy-flow`);
                            }}
                        >
                            小迪C6
                        </li> */}
                    </ul>
                    {/* <ul className="entry-sendi ul-item">
                        <li className="title">关于我们</li>
                        <li
                            className="item"
                            onClick={() => {
                                gotoUrl(
                                    "about-us",
                                    `${prefixRoute}/entry-sendi`
                                );
                            }}
                        >
                            企业简介
                        </li>
                        <li
                            className="item"
                            onClick={() => {
                                gotoUrl(
                                    "company-progress",
                                    `${prefixRoute}/entry-sendi`
                                );
                            }}
                        >
                            企业历程
                        </li>
                        <li
                            className="item"
                            onClick={() => {
                                gotoUrl(
                                    "company-culture",
                                    `${prefixRoute}/entry-sendi`
                                );
                            }}
                        >
                            企业文化
                        </li>
                        <li
                            className="item"
                            onClick={() => {
                                gotoUrl(
                                    "honorary-qualification",
                                    `${prefixRoute}/entry-sendi`
                                );
                            }}
                        >
                            荣誉资质
                        </li>
                    </ul> */}
                    <ul className="contact-us ul-item">
                        <li className="title">联系我们</li>
                        <li className="item">电话：020-87364981</li>
                        <li className="item">热线：400-830-3030</li>
                        <li className="item">邮箱：sendirobot@gzsendi.cn</li>
                        <li className="item">邮编：510600</li>
                    </ul>
                </li>
                <li className="follow-us">
                    <div>
                        <span className="wechat" />
                        扫码关注
                    </div>
                    <div>
                        <span className="wechat-video" />
                        扫码查看案例
                    </div>
                    <div>
                        <span className="wechat-business" />
                        联系我们
                    </div>
                </li>
            </ul>
            {/* <ul className="friendly-link">
                <li className="title">友情链接</li>
                <li className="item">广州申迪</li>
                <li className="item">广州云迪</li>
                <li className="item">广州扬迪</li>
                <li className="item">珠海申迪</li>
            </ul> */}
            <small>
                {`Copyright © ${date.getFullYear()} 广州申迪智能科技有限公司`}
            </small>
        </footer>
    );
};

export default BottomFooter;
