/**
 * 容器边框高亮流动动画
 * 接收的props：
 * com 容器组件
 * lightColor 边框高亮颜色
 */
import React, { useEffect } from 'react';
import styled from 'styled-components';

const Content = styled.div`
    overflow: hidden;
    position: relative;
    .light{
        position: absolute;
        display: block;
    }
    .light:nth-child(1) {
        @keyframes animate1 {
            0% {
            left: -100%;
            }

            50%,
            100% {
            left: 100%;
            }
        }
        /* 色调旋转滤镜 */
        filter: hue-rotate(0deg);
        top: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background: ${(props) => `linear-gradient(90deg,transparent,${props.lightColor})`};
        /* background: linear-gradient(90deg, transparent, #3a86ff); */
        animation: animate1 8s linear infinite;
    }
    .light:nth-child(2) {
        @keyframes animate2 {
            0% {
            top: -100%;
            }

            50%,
            100% {
            top: 100%;
            }
        }
        /* 色调旋转滤镜 */
        filter: hue-rotate(60deg);
        top: -100%;
        right: 0;
        width: 3px;
        height: 100%;
        background: ${(props) => `linear-gradient(180deg,transparent,${props.lightColor})`};
        /* background: linear-gradient(180deg, transparent, #3a86ff); */
        animation: animate2 8s linear infinite;
        animation-delay: 2s;
    }
    .light:nth-child(3) {
        @keyframes animate3 {
            0% {
            right: -100%;
            height: 3px;
            }

            50%,
            100% {
            height: 2px;
            right: 100%;
            }
        }
        /* 色调旋转滤镜 */
        filter: hue-rotate(120deg);
        bottom: 0;
        right: 0;
        width: 100%;
        background: ${(props) => `linear-gradient(270deg,transparent,${props.lightColor})`};
        /* background: linear-gradient(270deg, transparent, #3a86ff); */
        animation: animate3 8s linear infinite;
        animation-delay: 4s;
    }
    .light:nth-child(4) {
        @keyframes animate4 {
            0% {
            bottom: -100%;
            }

            50%,
            100% {
            bottom: 100%;
            }
        }
        /* 色调旋转滤镜 */
        filter: hue-rotate(300deg);
        bottom: -100%;
        left: 0;
        width: 3px;
        height: 100%;
        background: ${(props) => `linear-gradient(360deg,transparent,${props.lightColor})`};
        /* background: linear-gradient(360deg, transparent, #3a86ff); */
        animation: animate4 8s linear infinite;
        animation-delay: 6s;
    }

`;

const Com = (props) => {
    useEffect(() => {
        // console.log(props);
        // console.log(props.children);
    }, []);

    return (
        <Content
            className={`${props.com.props.className} border-hover-light`}
            lightColor={props.lightColor || '#01f0e9'}
        >
            <span>
                <span className="light" />
                <span className="light" />
                <span className="light" />
                <span className="light" />
                {props.com.props.children || '按钮文字'}
            </span>
        </Content>
    );
};

export default Com;
