import React from 'react';

import './index.less';

const LineAnimate = () => (
    <div
        className="line-animate-wrap"
    >
        <div className="line" />
        <div className="ball run-bottom-right" />
    </div>
);

export default LineAnimate;
