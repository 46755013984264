import React, { useState, useEffect, useRef } from 'react';

import { throttle } from 'src/tools/utils';

import './index.less';

const BackTop = () => {
    const [isShow, setIsShow] = useState(false);
    const scrollTop = useRef(0);

    useEffect(() => {
        document.addEventListener('scroll', scrollToTop, true);
        return () => document.removeEventListener('scroll', scrollToTop);
    }, []);

    const scrollToTop = throttle(() => {
        // scrollTop为距离滚动条顶部高度
        const scrollTop = window.pageYOffset
                        || document.documentElement.scrollTop
                        || document.body.scrollTop;
        // console.log(scrollTop);
        // scrollTop.current = scrollTop;
        if (scrollTop > 600) {
            setIsShow(true);
        } else {
            setIsShow(false);
        }
    }, 100);

    const backToTopHandle = () => {
        const timer = setInterval(() => {
            const ispeed = Math.floor(-scrollTop.current / 5);
            const toTop = scrollTop.current + ispeed;
            // console.log('scrollTop.current: ', scrollTop.current);
            // console.log('toTop: ', toTop);
            // eslint-disable-next-line no-multi-assign
            document.documentElement.scrollTop = document.body.scrollTop = toTop;
            if (scrollTop.current === 0) {
                clearInterval(timer);
            }
        }, 16);
    };
    return (
        <div
            className={`${isShow ? 'show' : ''} back-top-wrap`}
            onClick={backToTopHandle}
        >
            <i className="icon" />
            <span className="text">回到顶部</span>
        </div>
    );
};

export default BackTop;
