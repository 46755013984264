/**
 * 鼠标移入按钮边框高亮流动动画
 * 接收的props：
 * com 按钮组件
 * lightColor 边框高亮颜色
 */
import React, { useEffect } from 'react';
import styled from 'styled-components';

const Content = styled.div`
    >span{
        position: relative;
        display: block;
        overflow: hidden;
        box-sizing: border-box;
        text-align: center;
        transition: all .1s linear;
    }
    .light{
        position: absolute ;
    }
    .light:nth-child(1) {
        top: 0;
        left: -100%;
        width: 100%;
        height: 2px;
        background: ${(props) => `linear-gradient(90deg,transparent,${props.lightColor})`};
        /* background: linear-gradient(90deg,transparent,#01f0e9); */
    }
    .light:nth-child(2) {
        top: -100%;
        right: 0;
        width: 2px;
        height: 100%;
        background: ${(props) => `linear-gradient(180deg,transparent,${props.lightColor})`};
        /* background: linear-gradient(180deg,transparent,#01f0e9); */
    }
    .light:nth-child(3) {
        bottom: 0;
        right: -100%;
        width: 100%;
        height: 2px;
        background: ${(props) => `linear-gradient(270deg,transparent,${props.lightColor})`};
        /* background: linear-gradient(270deg,transparent,#01f0e9); */
    }
    .light:nth-child(4) {
        bottom: -100%;
        left: 0;
        width: 2px;
        height: 100%;
        background: ${(props) => `linear-gradient(1turn,transparent,${props.lightColor})`};
        /* background: linear-gradient(1turn,transparent,#01f0e9); */
    }
    &:hover{
        .light:nth-child(1) {
            left: 100%;
            transition: .6s;
        }
        .light:nth-child(2) {
            top: 100%;
            transition: 1s;
            transition-delay: .1s;
        }
        .light:nth-child(3) {
            right: 100%;
            transition: 1s;
            transition-delay: .25s;
        }
        .light:nth-child(4) {
            bottom: 100%;
            transition: 1s;
            transition-delay: .45s;
        }
    }
`;

const Com = (props) => {
    useEffect(() => {
        // console.log(props);
        // console.log(props.children);
    }, []);

    return (
        <Content
            className={`${props.com.props.className} border-hover-light`}
            lightColor={props.lightColor || '#077EF5'}
        >
            <span>
                <span className="light" />
                <span className="light" />
                <span className="light" />
                <span className="light" />
                {props.com.props.children || '按钮文字'}
            </span>
        </Content>
    );
};

export default Com;
