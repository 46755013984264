// 是否boolean
export function isBoolean(o) {
    return Object.prototype.toString.call(o).slice(8, -1) === 'Boolean';
}

// 是否为移动端
export const isMobile = (ua) => !!ua.match(/AppleWebKit.*Mobile.*/);

// 从浏览器地址中获取对应参数(？后面的)
export const getQueryString = (name) => {
    let reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i');
    // 获取url中 ? 符后的字符串并正则匹配
    let r = window.location.search.substr(1).match(reg);
    let context = '';
    // eslint-disable-next-line prefer-destructuring
    r && (context = r[2]);
    reg = null;
    r = null;
    return context;
};

/**
 * 防抖
 * @param {*} fn 将执行的函数
 */
let timeId;

export function debounce(fn, timeDelay = 500, immediate) {
    if (isBoolean(timeDelay)) {
        [immediate, timeDelay = 500] = [timeDelay, immediate];
    }
    timeId ? clearTimeout(timeId) : immediate && fn.apply();
    timeId = setTimeout(() => {
        fn.apply();
    }, timeDelay);
}

/**
 * 节流
 * @param {*} fn 将执行的函数
 * @param {*} time 节流规定的时间
 */
export const throttle = (fn, time = 500) => {
    let timer = null;
    return (...args) => {
        // 若timer === false，则执行，并在指定时间后将timer重制
        if (!timer) {
            fn.apply(this, args);
            timer = setTimeout(() => {
                timer = null;
            }, time);
        }
    };
};

/**
 * 移入移出动画
 * @param {string} hoverClass 移入的dom的class名
 * @param {string} bgClass 背景图dom的class名
 * @param {number} frameNum 背景图帧数减一  （背景图的总帧数一般是奇数）
 * @param {number} bgWidth 背景图实际渲染的宽度
 * @param {number} bgHeight 背景图实际渲染的高度*
 */
export const animationMove = (
    {
        hoverClass = 'product-service-title',
        bgClass = 'title-img',
        frameNum = 20,
        bgWidth = 64,
        bgHeight = 1344,
    },
) => {
    const stepArr = [];
    const overTimmerList = [];
    const outTimmerList = [];
    const hoverDom = document.getElementsByClassName(hoverClass);
    const bgImg = document.getElementsByClassName(bgClass);
    const bgImgLength = bgImg.length || 4;

    const animate = (r) => {
        overTimmerList.push(`h${r}`);
        outTimmerList.push(`i${r}`);
        stepArr.push(0);
        const targetDom = hoverDom[r];
        targetDom.onmouseover = () => {
            clearInterval(outTimmerList[r]);
            overTimmerList[r] = setInterval(() => {
                if ((stepArr[r] < frameNum) && (bgWidth * stepArr[r] < bgHeight)) {
                    stepArr[r]++;
                    bgImg[r].style.backgroundPositionY = `${-bgWidth * stepArr[r]}px`;
                } else {
                    clearInterval(overTimmerList[r]);
                }
            }, 30);
        };
        targetDom.onmouseout = () => {
            clearInterval(overTimmerList[r]);
            outTimmerList[r] = setInterval(() => {
                if ((stepArr[r] > 0) && (bgWidth * stepArr[r] > 0)) {
                    stepArr[r]--;
                    bgImg[r].style.backgroundPositionY = `${-bgWidth * stepArr[r]}px`;
                } else {
                    clearInterval(outTimmerList[r]);
                }
            }, 30);
        };
    };

    for (let i = 0; i < bgImgLength; i++) {
        animate(i);
    }
};

export function backTop() {
    const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
    // console.log(currentScroll);
    if (currentScroll > 0) {
        window.scrollTo(0, 0);
    }
}
